import { useState } from "react";
import { useSelector } from "react-redux";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../Firebase";

import classes from "./ContactUs.module.css";
import NewHeader from "../components/NewHomePage/NewHeader";

function ContactUsPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const userData = useSelector((state) => state.auth.userData);

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handleMessageChange(e) {
    setMessage(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const ref = await addDoc(collection(db, "feedback"), {
      name: name,
      email: email,
      message: message,
      timestamp: serverTimestamp(),
    });

    if (ref.id) {
      alert("Your message has been submitted");
      setName("");
      setEmail("");
      setMessage("");
    } else {
      alert("There was an error submitting your message");
    }
  }

  return (
    <div id="contact-us-page">
      <NewHeader />
      <div className="flex justify-center px-5">
        <div className={classes.contactUsPageContent}>
          <div className="py-8">
            <h1 className="mt-8 mb-16 text-center pb-5 text-white text-4xl">
              Contact Us
            </h1>
            <div className={`${classes.card} ${"p-6 mt-8"}`}>
              <form>
                <label htmlFor="name">Full Name</label>
                <br />
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  className="mb-5 border border-black rounded-sm px-1"
                />
                <br />
                <label>Email</label>
                <br />
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="mb-5 border border-black rounded-sm px-1"
                />
                <br />
                <label>Your Message</label>
                <br />
                <textarea
                  id="message"
                  value={message}
                  onChange={handleMessageChange}
                  rows={5}
                  className="mb-7 border border-black rounded-sm px-1"
                />
                <br />
                <button
                  onClick={handleSubmit}
                  className={`${"border border-black rounded-sm"} ${
                    classes.submitButton
                  }`}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;
