import { ReactComponent as GoogleLogo } from "../../assets/ZippyNotes-logo/google-logo.svg";

const NewGoogleLoginButton = (props) => {
  const buttonBaseClasses =
    "flex items-center justify-center space-x-3 bg-white text-gray-700 font-medium py-2 px-4 rounded-md shadow-md hover:bg-gray-100 transition duration-200 ease-in-out";
  const buttonWidth = "w-[135px]"; // Fixed width of 135px

  // Debugging log to ensure userData is correct
  console.log("userData:", props.userData);

  return (
    <div id={props.id}>
      {props.isLoggedIn ? (
        <button
          onClick={props.onClick}
          className={`${buttonBaseClasses} ${buttonWidth}`}
        >
          <img
            src={props.userData.photoURL}
            referrerPolicy="no-referrer"
            alt="Google Profile Pic"
            className="h-6 w-6 rounded-full"
          />
          <span className="align-middle">Sign Out</span>
        </button>
      ) : (
        <button
          onClick={props.onClick}
          className={`${buttonBaseClasses} ${buttonWidth}`}
        >
          <GoogleLogo className="h-6 w-6 align-middle" />
          <span className="align-middle">Sign In</span>
        </button>
      )}
    </div>
  );
};

export default NewGoogleLoginButton;
