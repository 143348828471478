import React, { useRef, useImperativeHandle } from "react";
import { Form, useNavigate } from "react-router-dom";
import getVideoId from "get-video-id";

const NewCallToActionSection = (props, ref) => {
  const videoLinkRef = useRef();
  const navigate = useNavigate();

  // Use imperative handle if needed
  useImperativeHandle(ref, () => ({
    linkRef: videoLinkRef,
  }));

  function playVideoHandler(event) {
    event.preventDefault();

    const { id, service } = getVideoId(videoLinkRef.current.value);

    if (id && service) {
      navigate("/video-player", {
        state: {
          videoLink: videoLinkRef.current.value,
          selectedView: "editNote",
        },
      });
    } else {
      alert("Please enter a valid YouTube video URL");
    }
  }

  return (
    <section className="bg-[#121212] py-12 md:py-20">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        {/* Main Heading */}
        <h2 className="text-3xl md:text-5xl font-bold text-white mb-6 md:mb-8">
          Try ZippyNotes Now!
        </h2>

        {/* Subheading */}
        <p className="text-sm md:text-lg text-gray-400 max-w-2xl mx-auto mb-6 md:mb-10 px-2">
          Start taking timestamped notes on your favorite YouTube videos and
          enhance your learning experience.
        </p>

        {/* Input and Go Button */}
        <Form className="flex items-center justify-center w-full max-w-xs sm:max-w-md mx-auto">
          <input
            type="text"
            required
            ref={videoLinkRef}
            placeholder="Paste a YouTube Video Link"
            className="flex-grow text-sm md:text-base rounded-l-md bg-white text-gray-800 border border-gray-300 focus:outline-none h-8 md:h-10 px-2"
          />
          <button
            onClick={playVideoHandler}
            className="bg-[#FE4949] text-white font-semibold rounded-r-md hover:bg-opacity-90 transition duration-200 h-8 md:h-10 px-3 md:px-4 text-sm md:text-base"
          >
            Go
          </button>
        </Form>
      </div>
    </section>
  );
};

// Wrap the component with forwardRef for ref forwarding
export default React.forwardRef(NewCallToActionSection);
