import { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc, query } from "firebase/firestore";
import { db } from "../Firebase";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

import VideoCard from "../components/VideosPage/VideoCard";
import NewHeader from "../components/NewHomePage/NewHeader.jsx";

import classes from "./Videos.module.css";

function VideosPage() {
  const [videos, setVideos] = useState(null);
  const [isFetchingVideos, setIsFetchVideos] = useState(false);
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    setIsFetchVideos(true);

    async function fetchVideos() {
      const videosSnapshot = await getDocs(
        collection(db, "users", userData.uid, "videos")
      );
      const newVideos = [];
      videosSnapshot.forEach((video) => {
        console.log(video.id, " => ", video.data());
        newVideos.push({
          videoId: video.id,
          videoData: video.data(),
        });
      });
      setVideos(newVideos);
      setIsFetchVideos(false);
    }

    fetchVideos();
  }, [userData.uid]);

  async function handleDeleteVideo(videoId) {
    var index = videos.findIndex((video) => video.videoId === videoId);
    const newVideos = JSON.parse(JSON.stringify(videos));
    newVideos.splice(index, 1);
    setVideos(newVideos);
    console.log(newVideos);

    const querySnapshot = await getDocs(
      collection(db, "users", userData.uid, "videos", videoId, "notes")
    );
    querySnapshot.forEach(async (document) => {
      console.log(document.id);
      await deleteDoc(
        doc(db, "users", userData.uid, "videos", videoId, "notes", document.id)
      );
    });

    await deleteDoc(doc(db, "users", userData.uid, "videos", videoId));
  }

  return (
    <div>
      <NewHeader />
      <div className={`${classes.videosPage} ${"flex justify-center p-5"}`}>
        <div className={classes.videosPageContent}>
          <h1 className="pl-2">Videos</h1>
          {!isFetchingVideos && (!videos || videos.length === 0) && (
            <p className={`${classes.noVideos} ${"px-3 py-4 text-white"}`}>
              You have not taken notes on any videos.
            </p>
          )}
          <div className={classes.videoCards}>
            <Grid container spacing={2} rowSpacing={8}>
              {videos &&
                videos.map((video) => (
                  <Grid
                    className="flex justify-center"
                    key={video.videoId}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <VideoCard
                      key={video.videoId}
                      id={video.videoId}
                      title={video.videoData.title}
                      channel={video.videoData.channel}
                      duration={video.videoData.duration}
                      thumbnail={video.videoData.thumbnail}
                      videoLink={video.videoData.link}
                      onDeleteVideo={handleDeleteVideo}
                      service={video.videoData.service}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideosPage;
