import React from "react";
import NewHeader from "../components/NewHomePage/NewHeader"; // Import the Header component
import Hero from "../components/NewHomePage/Hero"; // Import the new Hero component
import SocialProofSection from "../components/NewHomePage/SocialProofSection";
import NewFeaturesSection from "../components/NewHomePage/NewFeaturesSection";
import NewUseCasesSection from "../components/NewHomePage/NewUseCasesSection";
import NewCallToActionSection from "../components/NewHomePage/NewCallToActionSection";
import NewFooterSection from "../components/NewHomePage/NewFooterSection";

const NewHomePage = () => {
  return (
    <div className="relative min-h-screen bg-black text-white">
      {/* Include the Header component */}
      <NewHeader />

      {/* Replace the existing hero section with the new Hero component */}
      <Hero />
      <SocialProofSection />
      <NewFeaturesSection />
      <NewUseCasesSection />
      <NewCallToActionSection />
      <NewFooterSection />

      {/* Add other sections like Features, Use Cases, Testimonials, etc. */}
      {/* Features Section */}
      {/* Use Cases Section */}
      {/* Testimonials Section */}
      {/* Call to Action Section */}
    </div>
  );
};

export default NewHomePage;
