import React, { useState } from "react";
import logo from "../../assets/ZippyNotes-logo/logo.png";
import { ReactComponent as GoogleLogo } from "../../assets/ZippyNotes-logo/google-logo.svg";
import NewGoogleLoginButton from "../UI/NewGoogleLoginButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signInGoogle, signOutGoogle } from "../../store/auth-actions";

const NewHeader = () => {
  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function signInHandler() {
    dispatch(signInGoogle());
  }

  function signOutHandler() {
    dispatch(signOutGoogle());
    navigate("/");
  }

  function handleNavigate(path) {
    navigate(path);
    setIsMenuOpen(false); // Close menu after navigation
  }

  function handleExtensionNavigation() {
    window.open(
      "https://chrome.google.com/webstore/detail/zippynotes-take-notes-on/agjffdegfjfpeefmeapjnlephbkahooo?hl=en"
    );
    setIsMenuOpen(false); // Close menu after external link
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="w-full bg-transparent">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between py-4">
        {/* Logo Section */}
        <div className="flex-shrink-0">
          <img
            onClick={() => handleNavigate("/")}
            className="h-12 w-auto cursor-pointer"
            src={logo}
            alt="ZippyNotes"
          />
        </div>

        {/* Navigation Links for Desktop */}
        <nav className="hidden md:flex space-x-8">
          {isLoggedIn ? (
            <>
              <div
                onClick={() => handleNavigate("/videos")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                My Videos
              </div>
              <div
                onClick={handleExtensionNavigation}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Chrome Extension
              </div>
              <div
                onClick={() => handleNavigate("/pricing")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Pricing
              </div>
              <div
                onClick={() => handleNavigate("/contact-us")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Contact
              </div>
            </>
          ) : (
            <>
              <div
                onClick={handleExtensionNavigation}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Chrome Extension
              </div>
              <div
                onClick={() => handleNavigate("/pricing")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Pricing
              </div>
              <div
                onClick={() => handleNavigate("/contact-us")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Contact
              </div>
            </>
          )}
        </nav>

        {/* Google Login Button - Hidden on Mobile */}
        <div className="hidden md:block">
          <NewGoogleLoginButton
            id="googleLoginButton"
            onClick={!isLoggedIn ? signInHandler : signOutHandler}
            userData={userData}
            isLoggedIn={userData === null ? false : true}
          />
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            type="button"
            className="text-white focus:outline-none"
            aria-label="Open menu"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Slide-Out Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 z-50 flex">
          <div className="bg-gray-900 text-white w-64 p-6">
            <div className="space-y-6">
              <div
                onClick={() => handleNavigate("/")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Home
              </div>
              {isLoggedIn ? (
                <div
                  onClick={() => handleNavigate("/videos")}
                  className="text-white hover:text-gray-300 cursor-pointer"
                >
                  My Videos
                </div>
              ) : null}
              <div
                onClick={handleExtensionNavigation}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Chrome Extension
              </div>
              <div
                onClick={() => handleNavigate("/pricing")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Pricing
              </div>
              <div
                onClick={() => handleNavigate("/contact-us")}
                className="text-white hover:text-gray-300 cursor-pointer"
              >
                Contact
              </div>
              <NewGoogleLoginButton
                id="googleLoginButtonMobile"
                onClick={!isLoggedIn ? signInHandler : signOutHandler}
                userData={userData}
                isLoggedIn={userData === null ? false : true}
              />
            </div>
          </div>
          <div className="flex-grow" onClick={toggleMenu} />{" "}
          {/* Click outside to close */}
        </div>
      )}
    </header>
  );
};

export default NewHeader;
