import React, { useRef, useImperativeHandle } from "react";
import { Form, useNavigate } from "react-router-dom";
import getVideoId from "get-video-id";

const HeroSection = (props, ref) => {
    //   const [youtubeLink, setYoutubeLink] = useState('');

    //   const handleInputChange = (event) => {
    //     setYoutubeLink(event.target.value);
    //   };

    //   const handleGoClick = () => {
    //     if (youtubeLink) {
    //       // Implement your logic to handle the YouTube link
    //       console.log('YouTube Link:', youtubeLink);
    //       // Redirect or process the link
    //     }
    //   };

    const videoLinkRef = useRef();
    const navigate = useNavigate();

    useImperativeHandle(ref, () => {
        return {
            linkRef: videoLinkRef,
        };
    });

    function playVideoHandler(event) {
        event.preventDefault();

        const { id, service } = getVideoId(videoLinkRef.current.value);

        if (id && service) {
            navigate("/video-player", {
                state: {
                    videoLink: videoLinkRef.current.value,
                    selectedView: "editNote",
                },
            });
        } else {
            alert("Please enter a valid YouTube video URL");
        }
    }

    return (
        <section className="py-20 md:py-32">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center text-center">
                {/* Main Heading */}
                <h1 className="text-5xl md:text-7xl font-bold mb-12">
                    Maximize Your Learning on <span style={{ color: '#6B5596' }}>YouTube</span>
                </h1>

                {/* Subheading */}
                <p className="text-lg md:text-xl text-gray-300 max-w-2xl mb-16">
                    Take timestamped notes, keep them organized, and AI-summarize YouTube videos.
                </p>

                {/* Input and Go Button */}
                <Form className="flex items-center w-full sm:max-w-md max-w-xs mb-16">
                    <input
                        type="text"
                        required
                        ref={videoLinkRef}
                        placeholder="Paste a YouTube Video Link"
                        className="flex-grow text-xs md:text-sm rounded-l-md bg-white text-gray-800 border border-gray-300 focus:outline-none h-8 md:h-10 px-2" // Added padding
                    />
                    <button
                        onClick={playVideoHandler}
                        className="bg-[#FE4949] text-white font-semibold rounded-r-md hover:bg-opacity-90 transition duration-200 h-8 md:h-10 px-4 text-sm md:text-lg"
                    >
                        Go
                    </button>
                </Form>
            </div>
        </section>
    );
};

export default React.forwardRef(HeroSection);
