import React from "react";
import FeatureIcon1 from "../../assets/ZippyNotes-logo/Features Images/AI Feature.png";
import FeatureIcon2 from "../../assets/ZippyNotes-logo/Features Images/Chrome Extension.png";
import FeatureIcon3 from "../../assets/ZippyNotes-logo/Features Images/Save as PDF.png";

const NewFeaturesSection = () => {
  const features = [
    {
      title: (
        <>
          <span className="text-[#6B5596]">AI-Powered</span> Note Generation
        </>
      ),
      description:
        "Save hours of transcribing by letting our AI create timestamped summaries for your educational videos.",
      icon: FeatureIcon1,
      textOnLeft: true,
    },
    {
      title: (
        <>
          Chrome <span className="text-[#6B5596]">Extension</span>
        </>
      ),
      description:
        "Take notes directly on YouTube in real-time while watching videos, seamlessly integrate them to your account.",
      icon: FeatureIcon2,
      textOnLeft: false,
    },
    {
      title: (
        <>
          Save as <span className="text-[#6B5596]">PDF</span>
        </>
      ),
      description:
        "Save your meticulously crafted notes as PDFs with just a click, and conveniently review them later.",
      icon: FeatureIcon3,
      textOnLeft: true,
    },
  ];

  return (
    <section className="bg-[#0f0e17] py-20 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Features Bubble */}
        <div className="text-center mb-12 md:mb-16">
          <div className="inline-block px-6 py-2 border border-gray-500 rounded-full">
            <h2 className="text-white text-lg md:text-xl">Features</h2>
          </div>
        </div>

        {/* Updated Section Heading */}
        <div className="text-center mb-16 md:mb-20">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Discover Our Core Features
          </h2>
          <p className="text-gray-300 text-md md:text-lg max-w-2xl mx-auto">
            Explore the tools and integrations that make ZippyNotes the perfect
            companion for your YouTube learning journey.
          </p>
        </div>

        {features.map((feature, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row ${
              feature.textOnLeft ? "" : "md:flex-row-reverse"
            } items-center mb-12 md:mb-16 lg:mb-20`}
          >
            {/* Text Section */}
            <div className="md:w-1/2 text-center md:text-left mx-12 my-8">
              <h3 className="text-3xl md:text-4xl font-bold text-white mb-4">
                {feature.title}
              </h3>
              <p className="text-gray-300 text-base md:text-lg">
                {feature.description}
              </p>
            </div>
            {/* Image Section */}
            <div
              className={`md:w-1/2 flex justify-${
                feature.textOnLeft ? "start" : "end"
              } mb-8 md:mb-0 mx-12`}
            >
              <div className="w-full border border-gray-800 px-12 py-8 rounded-xl shadow-lg flex justify-center items-center transition-transform transform hover:scale-105 hover:shadow-2xl hover:border-[#6B5596]">
                <img
                  src={feature.icon}
                  alt={feature.title}
                  className="max-w-full h-auto rounded-lg object-contain transition-transform duration-300"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewFeaturesSection;
